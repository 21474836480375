// primary
$primaryBlue: rgba(18, 78, 245, 1);
$primaryWhite: rgba(255, 255, 255, 1);
$buttonHoverShadow: rgba(0, 0, 0, 0.1);

// loading page
$blueGradientT: rgba(18, 78, 245, 0.8);
$blueGradient: rgba(18, 78, 245, 0);
$greenGradientT: rgba(33, 204, 112, 0.8);
$greenGradient: rgba(33, 204, 112, 0);
$lightBlueGradientT: rgba(75, 172, 233, 0.8);
$lightBlueGradient: rgba(75, 172, 233, 0);
$lightGreenGradientT: rgba(107, 206, 150, 0.8);
$lightGreenGradient: rgba(107, 206, 150, 0);

// error page
$errorDescription: rgba(95, 99, 104, 1);
//$companyLogo: rgba(0, 88, 221, 1);
$companyLogo: rgba(15, 65, 204, 1);


// landing page
$bubbleBoxShadow: rgba(0, 0, 0, 0.25);
$accountName: rgba(101, 122, 144, 1);
$dropDown: rgba(251, 251, 251, 1);
$dropDownAccount: rgba(233, 236, 239, 1);
$accountLabel: rgba(59, 71, 84, 1);
$shortcutBorder: rgba(218, 222, 226, 1);
$lightGreyContainerShadow: rgba(255, 255, 255, 0.28);
$lightScrollbar: rgba(0, 0, 0, 0.10);
$darkScrollbar: rgba(0, 0, 0, 0.07);
$scrollBlack: rgba(0, 0, 0, 1);
$inputPlaceholder: rgba(169, 184, 198, 1);
$hoverButtonControls: rgba(235, 243, 255, 1);
$gradientLandingLight: rgba(203, 224, 255, 1);
$gradientLandingMiddle: rgba(203, 224, 255, 0.5);
$gradientLandingDark: rgba(203, 224, 255, 0);
$clientTextMessage: rgba(246, 246, 246, 1);
$scroll: rgba(217, 217, 217, 1);
$feedbackTitle: rgba(220, 220, 222, 1);
$feedbackButton: rgba(248, 248, 248, 1);
$backgroundFeedback: rgba(59, 71, 84, 0.50);
$closeButtonBackground: rgba(218, 222, 226, 1);
$feedbackModalBackground: rgba(243, 245, 246, 1);
$feedbackCheckbox: rgba(198, 208, 218, 1);
$modalBoxShadow: rgba(0, 0, 0, 0.56);
$relatedContent: rgba(90, 104, 118, 1);
$closeCall: rgba(201, 10, 10, 1);
$closeCallHover: rgba(162, 16, 16, 1);

$hubParagraph: rgba(35, 32, 40, 1);
$hubAssistantBubble: rgba(49, 64, 90, 1);
$hubShortcutHover: rgba(231, 237, 254, 1);
$hubInputPlaceholder: rgba(126, 124, 133, 1);
$hubInputBackground: rgba(249, 249, 249, 1);
$hubInputBorderBackground: rgba(235, 235, 235, 1);
$hubModalBackdrop: rgba(63, 59, 73, 1);
$hubCloseModalHover: rgba(182, 200, 252, 1);
$hubHeaderButtonShadow: rgba(123, 123, 123, 0.20);
$hubLanding: rgba(107, 67, 201, 1);
$hubEnterButton: rgba(137, 105, 212, 1);
$hubEnterButtonHover: rgba(97, 61, 183, 1);
$characterShadow: rgba(230, 230, 230, 1);
$hubCheckbox: rgba(195, 194, 197, 1);
$relatedQuestionHover: rgba(187, 169, 230, 1);
$loadingText: rgba(49, 64, 90, 1);
$newChatButtonHover: rgba(209, 197, 238, 1);
$formSentDescription: rgba(45, 42, 52, 1);
$contactFormTitle: rgba(26, 25, 31, 1);
