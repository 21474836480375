@import 'urls.scss';
@import 'colors.scss';
@import 'media.queries.scss';

.general-popup {
    max-width: 550px;
    width: 100%;
    height: 100%;
    max-height: 370px;
    border-radius: 32px;

    @media (max-width: $tabletXl) {
        width: 60%;
    }

    .swal2-actions {
        margin: 0 !important;
    }

    .swal2-confirm, .swal2-cancel {
        background: $primaryBlue;
        padding: 12px 32px;
        font-size: 1.4rem;
        font-family: 'InterMedium', sans-serif;
        color: $dropDown;
        transition: background 0.7s ease;
        border-radius: 24px;
    }

    .swal2-confirm:hover, .swal2-cancel:hover {
        background: $companyLogo;
    }

    .swal2-title, .swal2-html-container {
        color: $loadingText !important;
        margin: 0 !important;
        padding: 7px !important;
    }

    .swal2-title {
        font-size: 3.2rem !important;
        font-family: 'InterBold', sans-serif;

        @media (max-width: $tabletXl) {
            font-size: 2.5rem !important;
        }
    }

    .swal2-html-container {
        font-size: 1.6rem !important;
        font-family: 'InterMedium', sans-serif;

        @media (max-width: $tabletXl) {
            font-size: 1.2rem !important;
        }
    }

    .swal2-image {
        margin: 40px auto 10px auto;
        width: auto;
        height: 70%;

        @media (max-width: $tablet) {
            height: 60%;
        }
    }
}

// Loader
@keyframes resizeLoadBar {
    0%, 80%, 100% {
        height: 3em;
    }
    40% {
        height: 6em;
    }
}

.loader-popup {
    max-width: 550px;
    width: 100%;
    height: 100%;
    max-height: 400px;
    border-radius: 32px;

    @media (max-width: $tabletXl) {
        width: 60%;
    }

    .swal2-loader {
        display: none !important;
    }

    .swal2-actions {
        margin: 0 !important;
    }

    .swal2-title, .swal2-html-container {
        margin: 0 !important;
    }

    .swal2-title {
        font-size: 3.2rem !important;
        font-family: 'InterBold', sans-serif;
        padding: 15px !important;

        @media (max-width: $tabletXl) {
            font-size: 2.5rem !important;
        }
    }

    .swal2-html-container {
        font-size: 1.6rem !important;
        font-family: 'InterMedium', sans-serif;
        padding: 1rem !important;

        @media (max-width: $tabletXl) {
            font-size: 1.2rem !important;
        }
    }

    .loader-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        right: 0;
    }

    .loader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 9em;
        height: 40px;
        position: relative;
    }

    .loading-text {
        color: $loadingText;
        font-family: 'InterBold', sans-serif;
        font-size: 1.6rem;
        margin-top: 4.8rem;
        text-align: center;
    }

    .loader span {
        display: block;
        background: $primaryBlue;
        width: 1.5em;
        height: 4em;
        animation: resizeLoadBar 1s infinite ease-in-out;
        position: relative;
        border-radius: 20px;
    }

    .loader span:nth-child(1) {
        animation-delay: 0s;
    }

    .loader span:nth-child(2) {
        animation-delay: -0.3s;
    }

    .loader span:nth-child(3) {
        animation-delay: -0.6s;
    }

    .loader span:nth-child(4) {
        animation-delay: -0.9s;
    }

    .loader span:nth-child(5) {
        animation-delay: -1.2s;
    }
}
