@import 'constants/urls.scss';
@import 'constants/colors.scss';
@import 'constants/media.queries.scss';

@font-face {
    font-family: 'Roboto';
    src: url($fontFamilyRoboto);
}
@font-face {
    font-family: 'RobotoMedium';
    src: url($fontFamilyRobotoMedium);
}
@font-face {
    font-family: 'RobotoBold';
    src: url($fontFamilyRobotoBold);
}
@font-face {
    font-family: 'RobotoItalic';
    src: url($fontFamilyRobotoItalic);
}
@font-face {
    font-family: 'InterRegular';
    src: url($fontFamilyInterRegular);
}
@font-face {
    font-family: 'InterMedium';
    src: url($fontFamilyInterMedium);
}
@font-face {
    font-family: 'InterBold';
    src: url($fontFamilyInterBold);
}
@font-face {
    font-family: 'InterSemiBold';
    src: url($fontFamilyInterSemiBold);
}
@font-face {
    font-family: 'InterExtraBold';
    src: url($fontFamilyInterExtraBold);
}
@font-face {
    font-family: 'PoppinsBold';
    src: url($fontFamilyPoppinsBold);
}
@font-face {
    font-family: 'PoppinsSemiBold';
    src: url($fontFamilyPoppinsSemiBold);
}
@font-face {
    font-family: 'PoppinsMedium';
    src: url($fontFamilyPoppinsMedium);
}
@font-face {
    font-family: 'PoppinsRegular';
    src: url($fontFamilyPoppinsRegular);
}
@font-face {
    font-family: 'RocGroteskeWideExtraBold';
    src: url($fontFamilyRocGroteskeWideExtraBold);
}
:root {
    --circle-size: 80%;
    --blending: hard-light;
    --base-font-size: 16px;
}

* {
    box-sizing: border-box; // total width and height of an element include the content, padding, and border
}

html {
    font-size: 10px; /* 1rem = 10px */ /* 1.6rem = 16px */

    @media (max-width: $mobileL) {
        font-size: 8.75px; /* 1rem = 8.75px */ /* 1.6rem = 14px */
    }

    @media (max-width: $mobileS) {
        font-size: 7.5px; /* 1rem = 7.5px */ /* 1.6rem = 12px */
    }
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-style: normal;
    line-height: 100%;
    letter-spacing: -0.04em;
    word-wrap: break-word;
}

body {
    font-family: 'PoppinsRegular', sans-serif;
    font-size: 1.6rem; /* =16px */
}

p, h1, h2 {
    margin-block: 0;
}

button {
    border: none;
    cursor: pointer;
    margin: 0;
    transition: background-color 0.7s ease;
}

input, textarea {
    border: none;
    resize: none;
    padding: 0;
    line-height: normal;
}

textarea {
    white-space: pre-wrap;
}

input:focus, textarea:focus, button:focus {
    outline: none;
}

button:disabled, input:disabled, textarea:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

// vertical and horizontal scroll
::-webkit-scrollbar {
    width: 10px;
    height: 5px;

    @media (max-width: $tabletXl) {
        width: 5px;
    }
}

::-webkit-scrollbar-thumb {
    background-color: $scroll;
    height: 5px;
    border-radius: 20px;
}

.modal-backdrop {
    z-index: 1083 !important;
    background-color: $hubModalBackdrop !important;
}

.modal-dialog {
    @media (max-width: $tabletXl) {
        --bs-modal-margin: 16px;
    }
}

// svg components
app-arrow-back, app-arrow-next, app-attachment, app-bar, app-close,
app-dislike, app-like, app-document, app-edit, app-enter, app-image, app-microphone,
app-play, app-stop, app-arrow-down, app-arrow-top {
    display: contents;
}
